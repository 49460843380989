import { IUserInformation } from '~/core/@types/global'
import { timeFormatDate } from '~/core/utilities/format-date'
import {
  FirstStepFormType,
  InterviewDetailType,
  InterviewFormStep2
} from '../../calendar/types'
import { DATE_TIME_TYPE_VALUE } from '../../calendar/utilities/enum.cva'
import {
  changeTimezone,
  convertTimezone,
  formatDateWithTz,
  roundUpToNearestTwoHour
} from '../../calendar/utilities/helper-schedule-interview'
import { TFunction } from 'next-i18next'

const CANDIDATE_REMINDER_TIME_DEFAULT = '15_mins_before'
const FEEDBACK_REMINDER_TIME_DEFAULT = '15_mins_after'
import { reminderOptions } from '../ultilities/enum'

export const mappingScheduleInterview = (
  interviewInfo?: Partial<InterviewDetailType>,
  t?: TFunction
) => ({
  date: new Date(),
  startTime: {
    value: timeFormatDate(new Date()),
    supportingObj: {
      name: timeFormatDate(new Date())
    }
  },
  endTime: {
    value: timeFormatDate(roundUpToNearestTwoHour(new Date()).setMinutes(15)),
    supportingObj: {
      name: timeFormatDate(roundUpToNearestTwoHour(new Date()).setMinutes(15))
    }
  },
  remindBeforeTime: {
    value: interviewInfo?.remindBeforeTime || CANDIDATE_REMINDER_TIME_DEFAULT,
    supportingObj: {
      name: t
        ? `${t(
            `interview:timeRange:${
              interviewInfo?.remindBeforeTime || CANDIDATE_REMINDER_TIME_DEFAULT
            }`
          )}`
        : ''
    }
  },
  remindAfterTime: {
    value: interviewInfo?.remindAfterTime || FEEDBACK_REMINDER_TIME_DEFAULT,
    supportingObj: {
      name: t
        ? `${t(
            `interview:timeRange:${
              interviewInfo?.remindAfterTime || FEEDBACK_REMINDER_TIME_DEFAULT
            }`
          )}`
        : ''
    }
  },
  ...(interviewInfo?.fromDatetime && interviewInfo?.toDatetime
    ? {
        date: changeTimezone({
          date: interviewInfo?.fromDatetime,
          timezone: interviewInfo?.timezone
        }),
        startTime: {
          value:
            changeTimezone({
              date: interviewInfo?.fromDatetime,
              timezone: interviewInfo?.timezone
            })
              .toTimeString()
              .match(/[0-9]*:[0-9][0-9]/)?.[0] || '',
          supportingObj: {
            name:
              changeTimezone({
                date: interviewInfo?.fromDatetime,
                timezone: interviewInfo?.timezone
              })
                .toTimeString()
                .match(/[0-9]*:[0-9][0-9]/)?.[0] || ''
          }
        }
      }
    : {}),

  ...(interviewInfo?.toDatetime
    ? {
        endTime: {
          value:
            changeTimezone({
              date: interviewInfo?.toDatetime,
              timezone: interviewInfo?.timezone
            })
              .toTimeString()
              .match(/[0-9]*:[0-9][0-9]/)?.[0] || '',
          supportingObj: {
            name:
              changeTimezone({
                date: interviewInfo?.toDatetime,
                timezone: interviewInfo?.timezone
              })
                .toTimeString()
                .match(/[0-9]*:[0-9][0-9]/)?.[0] || ''
          }
        }
      }
    : {}),
  ...(interviewInfo?.timezone
    ? {
        timezone: {
          value: interviewInfo?.timezone,
          label: convertTimezone(interviewInfo?.timezone),
          supportingObj: { name: interviewInfo?.timezone }
        }
      }
    : {}),
  ...(interviewInfo?.eventType
    ? {
        eventType: {
          value: interviewInfo?.eventType,
          supportingObj: {
            name: interviewInfo?.eventTypeDescription || ''
          }
        }
      }
    : {}),
  ...(interviewInfo?.meetingUrl
    ? { meetingUrl: interviewInfo?.meetingUrl }
    : {}),

  ...(interviewInfo?.profile?.id
    ? { profileId: Number(interviewInfo.profile?.id) }
    : {}),

  ...(interviewInfo?.location?.id
    ? {
        locationId: {
          value: String(interviewInfo?.location?.id),
          supportingObj: {
            name: [
              interviewInfo?.location?.address,
              interviewInfo?.location?.city,
              interviewInfo?.location?.state,
              interviewInfo?.location?.country
            ]
              .filter((item) => item)
              .join(', ')
          }
        }
      }
    : {}),

  ...(interviewInfo?.emailTemplate?.id
    ? {
        emailTemplate: {
          value: String(interviewInfo.emailTemplate.id),
          supportingObj: {
            name: interviewInfo.emailTemplate.name || ''
          }
        }
      }
    : {}),
  ...(interviewInfo?.attendees && interviewInfo?.attendees?.length > 0
    ? {
        attendees: interviewInfo?.attendees?.map((attendee) => ({
          value: String(attendee.id),
          label: attendee.roles?.[0]?.name,
          avatar: attendee.avatarVariants?.thumb?.url,
          avatarVariants: attendee?.avatarVariants,
          availableForSchedule: attendee?.availableForSchedule,
          supportingObj: {
            defaultColour: attendee?.defaultColour || '',
            name:
              attendee?.fullName ||
              ((Array.isArray(attendee?.email)
                ? attendee?.email?.[0]
                : attendee?.email) as string),
            description: Array.isArray(attendee?.email)
              ? attendee?.email?.[0]
              : attendee?.email
          }
        }))
      }
    : {}),

  subject: interviewInfo?.emailTemplate?.subject,
  htmlBody: interviewInfo?.emailTemplate?.body,
  ...(interviewInfo?.jobIkit?.id
    ? {
        jobIkitId: {
          value: String(interviewInfo.jobIkit.id),
          supportingObj: {
            name: interviewInfo.jobIkit.name || ''
          }
        }
      }
    : {}),
  ...(interviewInfo?.jobStage?.id
    ? {
        jobStageId: {
          value: String(interviewInfo.jobStage.id),
          supportingObj: {
            name: interviewInfo.jobStage.stageLabel || ''
          }
        }
      }
    : {}),
  method:
    (interviewInfo?.interviewTimeSlots || [])?.length > 0
      ? DATE_TIME_TYPE_VALUE.self_schedule
      : DATE_TIME_TYPE_VALUE?.specific,
  timeSlots: (interviewInfo?.interviewTimeSlots || []).map((timeSlot) => {
    const interviewTz = convertTimezone(interviewInfo?.timezone)

    const startDate = new Date(
      new Date(timeSlot?.fromDatetime).toLocaleString('en-us', {
        timeZone: interviewInfo?.timezone
      })
    )
    const endDate = new Date(
      new Date(timeSlot?.toDatetime).toLocaleString('en-us', {
        timeZone: interviewInfo?.timezone
      })
    )
    return {
      ...timeSlot,
      timezone: interviewInfo?.timezone,
      fromDatetime: formatDateWithTz(startDate, interviewTz),
      toDatetime: formatDateWithTz(endDate, interviewTz)
    }
  }),
  duration: interviewInfo?.duration || {
    value: '1_hour'
  },
  caseEditGetPickedTimeSlot:
    (interviewInfo?.interviewTimeSlots || []).filter((i) => i.picked).length >
    0,
  sendEmailToCandidate:
    interviewInfo?.sendEmailToCandidate === undefined
      ? true
      : interviewInfo?.sendEmailToCandidate,
  sendEmailToAttendees:
    interviewInfo?.sendEmailToAttendees === undefined
      ? true
      : interviewInfo?.sendEmailToAttendees,
  remindSchedule:
    interviewInfo?.remindSchedule === undefined
      ? true
      : interviewInfo?.remindSchedule
})

export const mappingDefaultValueStep2 = ({
  interviewSubmitData,
  interviewInfo,
  getEmailInterviewInfo,
  currentUser
}: {
  interviewSubmitData?: FirstStepFormType & InterviewFormStep2
  interviewInfo?: InterviewDetailType
  getEmailInterviewInfo?: number
  currentUser?: IUserInformation
}) => {
  return {
    sendEmailToCandidate:
      interviewInfo?.sendEmailToCandidate === undefined
        ? true
        : interviewInfo?.sendEmailToCandidate,
    sendEmailToAttendees:
      interviewInfo?.sendEmailToAttendees === undefined
        ? true
        : interviewInfo?.sendEmailToAttendees,
    htmlBodyCandidate: interviewInfo?.emailTemplate?.body,
    subjectCandidate: interviewInfo?.emailTemplate?.subject,
    to: getEmailInterviewInfo
      ? [
          {
            value: String(interviewInfo?.profile?.email),
            supportingObj: {
              name: String(interviewInfo?.profile?.email)
            }
          }
        ]
      : undefined,
    ccAttendees: [], //ccList || [],
    bccAttendees: [],
    toAttendees: [
      ...(!!currentUser &&
      !!(interviewSubmitData?.attendees || []).find(
        (attendee) => Number(attendee.value) === Number(currentUser?.id)
      )
        ? []
        : [
            {
              value: currentUser?.email,
              supportingObj: {
                name: currentUser?.email
              }
            }
          ]),
      ...(interviewSubmitData?.attendees || [])?.map((item) => ({
        value: String(item.supportingObj?.description),
        supportingObj: {
          name: String(item.supportingObj?.description)
        }
      }))
    ],
    ...(interviewSubmitData?.sendEmailToCandidate !== undefined
      ? { sendEmailToCandidate: interviewSubmitData.sendEmailToCandidate }
      : {}),
    ...(interviewSubmitData?.sendEmailToAttendees !== undefined
      ? { sendEmailToAttendees: interviewSubmitData.sendEmailToAttendees }
      : {}),
    ...((interviewSubmitData?.to || [])?.length > 0
      ? { to: interviewSubmitData?.to }
      : {}),
    ...((interviewSubmitData?.ccAttendees || [])?.length > 0
      ? { ccAttendees: interviewSubmitData?.ccAttendees }
      : {}),
    ...((interviewSubmitData?.bccAttendees || [])?.length > 0
      ? { bccAttendees: interviewSubmitData?.bccAttendees }
      : {}),
    ...(!!interviewSubmitData?.subjectCandidate
      ? { subjectCandidate: interviewSubmitData?.subjectCandidate }
      : {}),
    ...(!!interviewSubmitData?.htmlBodyCandidate
      ? { htmlBodyCandidate: interviewSubmitData?.htmlBodyCandidate }
      : {}),
    ...(!!interviewSubmitData?.emailTemplate
      ? { emailTemplate: interviewSubmitData?.emailTemplate }
      : {}),
    ...(!!interviewSubmitData?.htmlBodyAttendees
      ? { htmlBodyAttendees: interviewSubmitData?.htmlBodyAttendees }
      : {}),
    ...(!!interviewSubmitData?.subjectAttendees
      ? { subjectAttendees: interviewSubmitData?.subjectAttendees }
      : {})
  }
}
